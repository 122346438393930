import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { createImageVariantConfig } from '../../util/sdkLoader';
const perPage = 10;

const ADD_TO_FAVOURITES_LOADING = 'ADD_TO_FAVOURITES_LOADING';
const ADD_TO_FAVOURITES_SUCCESS = 'ADD_TO_FAVOURITES_SUCCESS';
const ADD_TO_FAVOURITES_ERROR = 'ADD_TO_FAVOURITES_ERROR';

const GET_FAVOURITES_LOADING = 'GET_FAVOURITES_LOADING';
const GET_FAVOURITES_SUCCESS = 'GET_FAVOURITES_SUCCESS';
const GET_FAVOURITES_ERROR = 'GET_FAVOURITES_ERROR';

const initialState = {
  adding: false,
  addSuccess: false,
  addError: null,
  favouritesLoading: false,
  favouritesError: null,
  favouriteIds: [],
};

const FavouritesPageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_TO_FAVOURITES_LOADING:
      return { ...state, addError: null, adding: payload };
    case ADD_TO_FAVOURITES_SUCCESS:
      return { ...state, addError: null, adding: false, addSuccess: true };
    case ADD_TO_FAVOURITES_ERROR:
      return { ...state, addError: payload, adding: false, addSuccess: false };
    case GET_FAVOURITES_LOADING:
      return { ...state, favouritesError: null, favouritesLoading: true };
    case GET_FAVOURITES_SUCCESS:
      return { ...state, favouritesError: null, favouritesLoading: false, favouriteIds: payload };
    case GET_FAVOURITES_ERROR:
      return { ...state, favouritesError: payload, favouritesLoading: false };
    default:
      return { ...state };
  }
};
export default FavouritesPageReducer;

export const addingRequest = listingId => ({
  type: ADD_TO_FAVOURITES_LOADING,
  payload: listingId,
});
export const addingSuccess = () => ({
  type: ADD_TO_FAVOURITES_SUCCESS,
});
export const addingError = e => ({
  type: ADD_TO_FAVOURITES_ERROR,
  payload: e,
});

export const getFavouritesRequest = () => ({
  type: GET_FAVOURITES_LOADING,
});
export const getFavouritesSuccess = ids => ({
  type: GET_FAVOURITES_SUCCESS,
  payload: ids,
});
export const getFavouritesError = e => ({
  type: GET_FAVOURITES_ERROR,
  payload: e,
});

export const addOrRemoveFavourites = (listingId, favourites) => (dispatch, getState, sdk) => {
  dispatch(addingRequest(listingId));
  const isFavourite = favourites.includes(listingId);
  const newFavs = isFavourite
    ? favourites.filter(fid => fid !== listingId)
    : [...favourites, listingId];
  const updateValues = {
    privateData: {
      favourites: newFavs,
    },
  };
  const queryParams = {
    expand: true,
    include: ['profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };
  return sdk.currentUser
    .updateProfile(updateValues, queryParams)
    .then(response => {
      dispatch(addingSuccess());
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }
      const currentUser = entities[0];
      dispatch(currentUserShowSuccess(currentUser));
    })
    .catch(err => {
      console.log(err);
      dispatch(addingError(err));
    });
};
export const getAllFavourites = ({ page }) => (dispatch, getState, sdk) => {
  dispatch(getFavouritesRequest());
  const { currentUser } = getState().user;
  const favourites = currentUser?.attributes?.profile?.privateData?.favourites || [];
  if (favourites.length === 0) {
    dispatch(getFavouritesSuccess([]));
    return Promise.resolve();
  }
  const listingQueryParams = {
    ids: favourites,
    include: ['images', 'author', 'author.profileImage'],
    'fields.image': [
      'variants.default',
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.listing-card`,
      `variants.listing-card-2x`,
      `variants.square-small`,
    ],
    ...createImageVariantConfig(`listing-card`, 400, 0.75),
    ...createImageVariantConfig(`listing-card-2x`, 800, 0.75),
    'limit.images': 1,
    perPage,
    page,
  };
  return sdk.listings
    .query(listingQueryParams)
    .then(resp => {
      dispatch(addMarketplaceEntities(resp));
      const resultIds = resp.data.data.map(l => l.id);
      dispatch(getFavouritesSuccess(resultIds));
    })
    .catch(err => {
      console.log(err);
      dispatch(getFavouritesError(err));
    });
};
export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { page = 1 } = search;
  return dispatch(getAllFavourites({ page }));
  Promise.resolve();
};
