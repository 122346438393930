import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import { setPagination } from '../../containers/Dashboard/Dashboard.duck';

const GET_AFFILIATION_DATA_REQUEST = 'GET_AFFILIATION_DATA_REQUEST';
const GET_AFFILIATION_DATA_SUCCESS = 'GET_AFFILIATION_DATA_SUCCESS';
const GET_AFFILIATION_DATA_ERROR = 'GET_AFFILIATION_DATA_ERROR';

const JOIN_AFFILIATE_REQUEST = 'JOIN_AFFILIATE_REQUEST';
const JOIN_AFFILIATE_SUCCESS = 'JOIN_AFFILIATE_SUCCESS';
const JOIN_AFFILIATE_ERROR = 'JOIN_AFFILIATE_ERROR';

const initialState = {
  componentLoading: false,
  affiliationDataLoading: false,
  affiliationData: null,
  affiliationDataError: null,
  joinLoading: false,
  joinError: null,
};

const DashboardAffiliateContentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_AFFILIATION_DATA_REQUEST:
      return {
        ...state,
        affiliationDataLoading: true,
        affiliationDataError: null,
      };
    case GET_AFFILIATION_DATA_SUCCESS:
      return {
        ...state,
        affiliationDataLoading: false,
        affiliationData: payload,
      };
    case GET_AFFILIATION_DATA_ERROR:
      return {
        ...state,
        affiliationDataLoading: false,
        affiliationDataError: payload,
      };

    case JOIN_AFFILIATE_REQUEST:
      return {
        ...state,
        joinLoading: true,
        joinError: null,
      };
    case JOIN_AFFILIATE_SUCCESS:
      return {
        ...state,
        joinLoading: false,
      };
    case JOIN_AFFILIATE_ERROR:
      return {
        ...state,
        joinLoading: false,
        joinError: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default DashboardAffiliateContentReducer;

const getAffiliationDataRequest = () => ({
  type: GET_AFFILIATION_DATA_REQUEST,
});
const getAffiliationDataSuccess = payload => ({
  type: GET_AFFILIATION_DATA_SUCCESS,
  payload,
});
const getAffiliationDataError = payload => ({
  type: GET_AFFILIATION_DATA_ERROR,
  payload,
});

const joinAffiliateRequest = () => ({
  type: JOIN_AFFILIATE_REQUEST,
});
const joinAffiliateSuccess = () => ({
  type: JOIN_AFFILIATE_SUCCESS,
});
const joinAffiliateError = payload => ({
  type: JOIN_AFFILIATE_ERROR,
  payload,
});

export const getAffiliationData = ({ page, affiliateTab, search }) => (dispatch, getState, sdk) => {
  dispatch(getAffiliationDataRequest());
  const formattedSearch = search ? search + '&source=dashboard' : '?source=dashboard';
  const getStatusUrl = `${apiBaseUrl()}/api/affiliate-program/affiliation-status${formattedSearch}`;
  return axios
    .get(getStatusUrl, { withCredentials: true })
    .then(res => {
      console.log(9966, res.data);
      dispatch(getAffiliationDataSuccess(res.data));
      dispatch(setPagination({ data: { meta: res.data.pagination } }));
    })
    .catch(err => {
      dispatch(getAffiliationDataError(err));
    });
};

export const joinAffiliate = () => (dispatch, getState, sdk) => {
  dispatch(joinAffiliateRequest());
  const requestAffiliateUrl = `${apiBaseUrl()}/api/affiliate-program/request-affiliate`;
  return axios
    .get(requestAffiliateUrl, { withCredentials: true })
    .then(res => {
      dispatch(joinAffiliateSuccess());
      dispatch(getAffiliationDataSuccess(res.data));
    })
    .catch(err => {
      dispatch(joinAffiliateError(err));
    });
};
