import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import { setPagination } from '../../containers/Dashboard/Dashboard.duck';

const GET_PAGE_USERS_REQUEST = 'GET_PAGE_USERS_REQUEST';
const GET_PAGE_USERS_SUCCESS = 'GET_PAGE_USERS_SUCCESS';
const GET_PAGE_USERS_ERROR = 'GET_PAGE_USERS_ERROR';

const initialState = {
  componentLoading: false,
  pageUsersLoading: false,
  pageUsers: [],
  pageUsersError: null,
  // joinLoading: false,
  // joinError: null,
};
const AdminDashboardAffiliateContentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PAGE_USERS_REQUEST:
      return {
        ...state,
        pageUsersLoading: true,
        pageUsersError: null,
      };
    case GET_PAGE_USERS_SUCCESS:
      return {
        ...state,
        pageUsersLoading: false,
        pageUsers: payload,
      };
    case GET_PAGE_USERS_ERROR:
      return {
        ...state,
        pageUsersLoading: false,
        pageUsersError: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
export default AdminDashboardAffiliateContentReducer;

export const getUsersRequest = () => ({
  type: GET_PAGE_USERS_REQUEST,
});
export const getUsersSuccess = payload => ({
  type: GET_PAGE_USERS_SUCCESS,
  payload,
});
export const getUsersError = payload => ({
  type: GET_PAGE_USERS_ERROR,
  payload,
});

export const getPageUsers = params => (dispatch, getState, sdk) => {
  dispatch(getUsersRequest());
  const { affiliateTab = 'affiliated', search } = params;
  const getUsersUrl = `${apiBaseUrl()}/api/affiliate-program/affiliated-users${search}`;

  return axios
    .get(getUsersUrl)
    .then(res => {
      dispatch(getUsersSuccess(res.data.data));
      dispatch(setPagination(res));
    })
    .catch(err => {
      dispatch(getUsersError(err));
    });
};
