import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

const AFFILIATE_DATA_REQUEST = 'AFFILIATE_LINKS_DETAILS_PAGE/AFFILIATE_DATA_REQUEST';
const AFFILIATE_DATA_SUCCESS = 'AFFILIATE_LINKS_DETAILS_PAGE/AFFILIATE_DATA_SUCCESS';
const AFFILIATE_DATA_ERROR = 'AFFILIATE_LINKS_DETAILS_PAGE/AFFILIATE_DATA_ERROR';

const LINK_DATA_REQUEST = 'AFFILIATE_LINKS_DETAILS_PAGE/LINK_DATA_REQUEST';
const LINK_DATA_SUCCESS = 'AFFILIATE_LINKS_DETAILS_PAGE/LINK_DATA_SUCCESS';
const LINK_DATA_ERROR = 'AFFILIATE_LINKS_DETAILS_PAGE/LINK_DATA_ERROR';

const UPDATE_COMMISSION_REQUEST = 'AFFILIATE_LINKS_DETAILS_PAGE/UPDATE_COMMISSION_REQUEST';
const UPDATE_COMMISSION_SUCCESS = 'AFFILIATE_LINKS_DETAILS_PAGE/UPDATE_COMMISSION_SUCCESS';
const UPDATE_COMMISSION_ERROR = 'AFFILIATE_LINKS_DETAILS_PAGE/UPDATE_COMMISSION_ERROR';

const ADD_PAYOUT_REQUEST = 'AFFILIATE_LINKS_DETAILS_PAGE/ADD_PAYOUT_REQUEST';
const ADD_PAYOUT_SUCCESS = 'AFFILIATE_LINKS_DETAILS_PAGE/ADD_PAYOUT_SUCCESS';
const ADD_PAYOUT_ERROR = 'AFFILIATE_LINKS_DETAILS_PAGE/ADD_PAYOUT_ERROR';

const SET_USER_ID = 'AFFILIATE_LINKS_DETAILS_PAGE/SET_USER_ID';
const SET_PAGINATION = 'AFFILIATE_LINKS_DETAILS_PAGE/SET_PAGINATION';

const PAYOUT_DETAILS_REQUEST = 'AFFILIATE_LINKS_DETAILS_PAGE/PAYOUT_DETAILS_REQUEST';
const PAYOUT_DETAILS_SUCCESS = 'AFFILIATE_LINKS_DETAILS_PAGE/PAYOUT_DETAILS_SUCCESS';
const PAYOUT_DETAILS_ERROR = 'AFFILIATE_LINKS_DETAILS_PAGE/PAYOUT_DETAILS_ERROR';

const DELETE_LINK_REQUEST = 'AFFILIATE_LINKS_DETAILS_PAGE/DELETE_LINK_REQUEST';
const DELETE_LINK_SUCCESS = 'AFFILIATE_LINKS_DETAILS_PAGE/DELETE_LINK_SUCCESS';
const DELETE_LINK_ERROR = 'AFFILIATE_LINKS_DETAILS_PAGE/DELETE_LINK_ERROR';

const initialState = {
  affiliateDataRequest: false,
  affiliateData: {},
  affiliateDataError: null,
  userId: {},
  updateCommisionRequest: false,
  updateCommissionSuccess: false,
  updateCommissionError: null,
  linkDataRequest: false,
  linkDataError: null,
  pagination: null,
  addPayoutRequest: false,
  addPayoutError: null,
  payoutDetailsRequest: false,
  payoutDetailsError: null,
  deleteLinkRequest: false,
  deleteLinkError: null,
};
const AffiliateLinksDetailsPageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DELETE_LINK_REQUEST:
      return {
        ...state,
        deleteLinkError: null,
        deleteLinkRequest: payload,
      };
    case DELETE_LINK_SUCCESS:
      return {
        ...state,
        deleteLinkRequest: false,
        deleteLinkError: null,
      };
    case DELETE_LINK_ERROR:
      return {
        ...state,
        deleteLinkRequest: false,
        deleteLinkError: payload,
      };

    case ADD_PAYOUT_REQUEST:
      return {
        ...state,
        addPayoutError: null,
        addPayoutRequest: true,
      };
    case ADD_PAYOUT_SUCCESS:
      return {
        ...state,
        affiliateData: { ...state.affiliateData, ...payload },
        addPayoutRequest: false,
        addPayoutError: null,
      };
    case ADD_PAYOUT_ERROR:
      return {
        ...state,
        addPayoutRequest: false,
        addPayoutError: payload,
      };

    case AFFILIATE_DATA_REQUEST:
      return {
        ...state,
        affiliateDataRequest: true,
        affiliateDataError: null,
      };
    case AFFILIATE_DATA_SUCCESS:
      return {
        ...state,
        affiliateDataRequest: false,
        affiliateData: payload,
      };
    case AFFILIATE_DATA_ERROR:
      return {
        ...state,
        affiliateDataRequest: false,
        affiliateDataError: payload,
      };
    case LINK_DATA_REQUEST:
      return {
        ...state,
        linkDataRequest: true,
        linkDataError: null,
      };
    case LINK_DATA_SUCCESS:
      return {
        ...state,
        linkDataRequest: false,
        affiliateData: payload,
      };
    case LINK_DATA_ERROR:
      return {
        ...state,
        linkDataRequest: false,
        linkDataError: payload,
      };
    case PAYOUT_DETAILS_REQUEST:
      return {
        ...state,
        payoutDetailsRequest: true,
        payoutDetailsError: null,
      };
    case PAYOUT_DETAILS_SUCCESS:
      return {
        ...state,
        payoutDetailsRequest: false,
        affiliateData: payload,
      };
    case PAYOUT_DETAILS_ERROR:
      return {
        ...state,
        payoutDetailsRequest: false,
        payoutDetailsError: payload,
      };
    case UPDATE_COMMISSION_REQUEST:
      return {
        ...state,
        updateCommisionRequest: true,
        updateCommissionError: null,
      };
    case UPDATE_COMMISSION_SUCCESS:
      return {
        ...state,
        affiliateData: { ...state.affiliateData, commission: payload },
        updateCommisionRequest: false,
        updateCommissionSuccess: true,
      };
    case UPDATE_COMMISSION_ERROR:
      return {
        ...state,
        updateCommisionRequest: false,
        updateCommissionError: payload,
      };
    case SET_USER_ID:
      return {
        ...state,
        userId: payload,
      };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    default:
      return { ...state };
  }
};
export default AffiliateLinksDetailsPageReducer;

export const deleteLinkRequest = key => {
  return {
    type: DELETE_LINK_REQUEST,
    payload: key,
  };
};
export const deleteLinkSuccess = payload => {
  return {
    type: DELETE_LINK_SUCCESS,
    // payload,
  };
};
export const deleteLinkError = error => {
  return {
    type: DELETE_LINK_ERROR,
    payload: error,
  };
};

export const addPayoutRequest = () => {
  return {
    type: ADD_PAYOUT_REQUEST,
  };
};
export const addPayoutSuccess = payload => {
  return {
    type: ADD_PAYOUT_SUCCESS,
    payload,
  };
};
export const addPayoutError = error => {
  return {
    type: ADD_PAYOUT_ERROR,
    payload: error,
  };
};

export const getAffiliateDataRequest = () => {
  return {
    type: AFFILIATE_DATA_REQUEST,
  };
};
export const getAffiliateDataSuccess = affiliateData => {
  return {
    type: AFFILIATE_DATA_SUCCESS,
    payload: affiliateData,
  };
};
export const getAffiliateDataError = error => {
  return {
    type: AFFILIATE_DATA_ERROR,
    payload: error,
  };
};
export const getLinkDataRequest = () => {
  return {
    type: LINK_DATA_REQUEST,
  };
};
export const getLinkDataSuccess = affiliateData => {
  return {
    type: LINK_DATA_SUCCESS,
    payload: affiliateData,
  };
};
export const getLinkDataError = error => {
  return {
    type: LINK_DATA_ERROR,
    payload: error,
  };
};

export const getPayoutDetailsRequest = () => {
  return {
    type: PAYOUT_DETAILS_REQUEST,
  };
};
export const getPayoutDetailsSuccess = affiliateData => {
  return {
    type: PAYOUT_DETAILS_SUCCESS,
    payload: affiliateData,
  };
};
export const getPayoutDetailsError = error => {
  return {
    type: PAYOUT_DETAILS_ERROR,
    payload: error,
  };
};

export const updateCommissionActionRequest = () => {
  return {
    type: UPDATE_COMMISSION_REQUEST,
  };
};
export const updateCommissionActionSuccess = commission => {
  return {
    type: UPDATE_COMMISSION_SUCCESS,
    payload: commission,
  };
};
export const updateCommissionActionError = error => {
  return {
    type: UPDATE_COMMISSION_ERROR,
    payload: error,
  };
};
export const setUserId = userId => {
  return {
    type: SET_USER_ID,
    payload: userId,
  };
};
export const setPagination = pagination => {
  return {
    type: SET_PAGINATION,
    payload: pagination,
  };
};
const getAffiliateData = ({ search }) => (dispatch, getState, sdk) => {
  dispatch(getAffiliateDataRequest());
  const getStatusUrl = `${apiBaseUrl()}/api/affiliate-program/affiliation-status${search}`;
  return axios
    .get(getStatusUrl, { withCredentials: true })
    .then(res => {
      const { pagination } = res.data;
      dispatch(setPagination(pagination));
      dispatch(getAffiliateDataSuccess(res.data));
    })
    .catch(err => {
      dispatch(getAffiliateDataError(storableError(err)));
    });
};

const getAffiliateDataAdmin = ({ userId, search }) => (dispatch, getState, sdk) => {
  dispatch(getAffiliateDataRequest());
  const getStatusUrl = `${apiBaseUrl()}/api/affiliate-program/admin-affiliation-status/${userId}${search}`;
  let affiliateResp;
  return axios
    .get(getStatusUrl, { withCredentials: true })
    .then(res => {
      affiliateResp = res.data;
      return axios.post(`${apiBaseUrl()}/api/admin/getUserById`, { id: userId });
    })
    .then(res => {
      dispatch(addMarketplaceEntities(res.data));
      dispatch(setUserId(res.data.data.data.id));
      dispatch(getAffiliateDataSuccess(affiliateResp));
      const { pagination } = affiliateResp;
      dispatch(setPagination(pagination));
    })
    .catch(err => {
      dispatch(getAffiliateDataError(storableError(err)));
    });
};

export const updateCommission = ({ userId, commission }) => (dispatch, getState, sdk) => {
  dispatch(updateCommissionActionRequest());
  const updateCommissionUrl = `${apiBaseUrl()}/api/affiliate-program/update-commission`;
  return axios
    .post(updateCommissionUrl, { userId, commission }, { withCredentials: true })
    .then(res => {
      dispatch(updateCommissionActionSuccess(commission));
      return res;
    })
    .catch(err => dispatch(updateCommissionActionError(err)));
};
export const getLinkData = ({ userId, linkKey, search }) => (dispatch, getState, sdk) => {
  dispatch(getLinkDataRequest());
  const getLinkDataUrl = `${apiBaseUrl()}/api/affiliate-program/link-data/signed-up-users/${userId}/${linkKey}${search}`;
  return axios
    .get(getLinkDataUrl, { withCredentials: true })
    .then(res => {
      const { pagination } = res.data;
      //   const signedupUsers = res.data?.affiliateLinks?.[0]?.signedupUsers;
      //   if (signedupUsers?.length > 0) {
      //     const getUsersUrl = `${apiBaseUrl()}/api/admin/getUsersByParam`;
      //     return axios.post(getUsersUrl, {});
      //   }
      dispatch(getLinkDataSuccess(res.data));
      dispatch(setPagination(pagination));
    })
    .catch(err => {
      dispatch(getLinkDataError(storableError(err)));
    });
};
export const addPayout = (userId, data) => (dispatch, getState, sdk) => {
  dispatch(addPayoutRequest());
  const addPayoutUrl = `${apiBaseUrl()}/api/affiliate-program/add-payout/${userId}`;
  const { payoutAmount: amount, payoutDate } = data;
  const body = { amount, payoutDate };
  return axios
    .post(addPayoutUrl, body, { withCredentials: true })
    .then(res => {
      const { totalPayment, lastPaymentDate, lastPayment, pendingPayment } = res.data;
      const updateData = {
        totalPayment,
        lastPaymentDate,
        lastPayment,
        pendingPayment,
      };
      dispatch(addPayoutSuccess(updateData));
      return res;
    })
    .catch(err => dispatch(addPayoutError(error)));
};
export const getPayoutDetails = params => (dispatch, getState, sdk) => {
  const { userId, userType, search } = params;
  dispatch(getPayoutDetailsRequest());
  const getPayoutDetailsUrl = `${apiBaseUrl()}/api/affiliate-program/${userType}/payouts/${userId}${search}`;
  return axios
    .get(getPayoutDetailsUrl, { withCredentials: true })
    .then(res => {
      const { pagination } = res.data;
      dispatch(getPayoutDetailsSuccess(res.data));
      dispatch(setPagination(pagination));
    })
    .catch(err => {
      dispatch(getPayoutDetailsError(storableError(err)));
    });
};

export const deleteLink = params => (dispatch, getState, sdk) => {
  const { userId, linkKey, isSuperAdmin } = params;
  dispatch(deleteLinkRequest(linkKey));
  const userType = isSuperAdmin ? 'admin' : 'user';
  const deleteUrl = `${apiBaseUrl()}/api/affiliate-program/${userType}/delete/${userId}/${linkKey}`;
  return axios
    .get(deleteUrl, { withCredentials: true })
    .then(res => {
      dispatch(deleteLinkSuccess());
      return true;
    })
    .catch(err => {
      dispatch(deleteLinkError(err));
    });
};

export const loadData = (params, search) => {
  if (params.pageType === 'signed-up-users' && params.linkKey && params.userId) {
    return getLinkData({ linkKey: params.linkKey, userId: params.userId, search });
  } else if (params.pageType === 'payouts' && params.userId) {
    return getPayoutDetails({ userId: params.userId, userType: params.userType, search });
  } else if (params.userType === 'admin' && params.userId) {
    return getAffiliateDataAdmin({ userId: params.userId, search });
  } else {
    return getAffiliateData({ search });
  }
};
