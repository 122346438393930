import React from 'react';
import css from './HorizontalTabNav.module.css';
import classNames from 'classnames';
import NamedLink from '../NamedLink/NamedLink';

import { ReactComponent as CheckedIcon } from './checked.svg';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';

const HorizontalTabNav = props => {
  const { tabs, tabsCompletedStatus, history } = props;
  // console.log(321, tabs, tabsCompletedStatus);

  const routes = useRouteConfiguration();

  return (
    <div className={css.horizontalNav}>
      <div className={css.tabContainer}>
        {tabs.map(({ id, disabled, linkProps, selected, text, tab }, index) => (
          // <div className={css.tabs} key={id}>
          <NamedLink
            className={classNames(css.tabs, disabled && css.disabled)}
            key={id}
            {...linkProps}
          >
            <div className={classNames(css.circleContainer, { [css.activeCircle]: !disabled })}>
              <div className={css.circle}>
                <div className={css.index}>
                  {tabsCompletedStatus[tab] && !selected ? <CheckedIcon /> : index + 1}
                </div>
              </div>
            </div>
            <div className={classNames(css.label, { [css.active]: !disabled })}>{text}</div>
            <div className={css.symbol}>&gt;</div>
          </NamedLink>
          // </div>
        ))}
      </div>
      <div
        className={css.exit}
        onClick={() => {
          history.push(createResourceLocatorString('LandingPage', routes, {}, {}));
        }}
      >
        Exit
      </div>
    </div>
  );
};

export default compose(withRouter)(HorizontalTabNav);
