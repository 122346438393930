import axios from 'axios';
import { storableError } from '../../util/errors';
import { addOwnEntities } from '../ManageListingsPage/ManageListingsPage.duck';
import { apiBaseUrl } from '../../util/api';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { getAffiliationData } from '../../components/DashboardAffiliateContent/DashboardAffiliateContent.duck';
import { getPageUsers } from '../../components/AdminDashboardAffiliateContent/AdminDashboardAffiliateContent.duck';

const { UUID } = sdkTypes;

const GET_LISTINGS_REQUEST = 'GET_LISTINGS_REQUEST';
const GET_LISTINGS_SUCCESS = 'GET_LISTINGS_SUCCESS';
const GET_LISTINGS_ERROR = 'GET_LISTINGS_ERROR';

const GET_PROVIDER_REQUEST = 'GET_PROVIDER_REQUEST';
const GET_PROVIDER_SUCCESS = 'GET_PROVIDER_SUCCESS';
const GET_PROVIDER_ERROR = 'GET_PROVIDER_ERROR';

const GET_PENDING_APPROVAL_REQUEST = 'GET_PENDING_APPROVAL_REQUEST';
const GET_PENDING_APPROVAL_SUCCESS = 'GET_PENDING_APPROVAL_SUCCESS';
const GET_PENDING_APPROVAL_ERROR = 'GET_PENDING_APPROVAL_ERROR';

const GET_PENDING_CERTIFICATION_REQUEST = 'GET_PENDING_CERTIFICATION_REQUEST';
const GET_PENDING_CERTIFICATION_SUCCESS = 'GET_PENDING_CERTIFICATION_SUCCESS';
const GET_PENDING_CERTIFICATION_ERROR = 'GET_PENDING_CERTIFICATION_ERROR';

const REMOVE_APPROVED_OR_REJECTED_FROM_LIST = 'REMOVE_APPROVED_OR_REJECTED_FROM_LIST';

const GET_AFFILIATION_PENDING_REQUEST = 'GET_AFFILIATION_PENDING_REQUEST';
const GET_AFFILIATION_PENDING_SUCCESS = 'GET_AFFILIATION_PENDING_SUCCESS';
const GET_AFFILIATION_PENDING_ERROR = 'GET_AFFILIATION_PENDING_ERROR';

const APPROVING = 'APPROVING';
const APPROVED = 'APPROVED';
const APPROVE_ERROR = 'APPROVE_ERROR';
const REMOVE_APPROVED = 'REMOVE_APPROVED';

const SET_PAGINATION = 'SET_PAGINATION';
const SET_PAGE_LOADING = 'SET_PAGE_LOADING';

const initialState = {
  listingsLoading: false,
  listingIds: [],
  listingsError: null,
  listingPagination: null,
  approving: false,
  approved: false,
  approveError: null,
  pendingApprovalIds: [],
  pendingApprovalPagination: null,
  pendingApprovalIdsLoading: false,
  pendingApprovalIdsError: null,
  providerRefs: [],
  providerPagination: null,
  providersRequest: false,
  providersError: null,
  pendingCertificationsLoading: false,
  pendingCertifications: [],
  pendingCertificationsPagination: null,
  pendingCertificationsError: null,
  certBeingUpdated: [],
  affiliationWithUsersRefs: [],
  affiliationWithListingsRequest: false,
  affiliationWithListingsError: null,
  pagination: null,
  pageLoading: false,
};
const dashboardReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PAGINATION:
      return { ...state, pagination: payload, pageLoading: false };
    case SET_PAGE_LOADING:
      return { ...state, pageLoading: payload };
    case GET_PROVIDER_REQUEST:
      return { ...state, providersRequest: true, providersError: null };
    case GET_PROVIDER_SUCCESS:
      return {
        ...state,
        providersRequest: false,
        providersError: null,
        providerRefs: payload.data,
      };
    case GET_PROVIDER_ERROR:
      return { ...state, providersRequest: false, providersError: payload };
    case GET_LISTINGS_REQUEST:
      return { ...state, listingsLoading: true, listingsError: null };
    case GET_LISTINGS_SUCCESS:
      return { ...state, listingsLoading: false, listingsError: null, listingIds: payload.data };
    case GET_LISTINGS_ERROR:
      return { ...state, listingsLoading: false, listingsError: payload };
    case GET_PENDING_APPROVAL_REQUEST:
      return { ...state, pendingApprovalIdsLoading: true, pendingApprovalIdsError: null };
    case GET_PENDING_APPROVAL_SUCCESS:
      return {
        ...state,
        pendingApprovalIdsLoading: false,
        pendingApprovalIdsError: null,
        pendingApprovalIds: payload.data,
      };
    case GET_PENDING_APPROVAL_ERROR:
      return { ...state, pendingApprovalIdsLoading: false, pendingApprovalIdsError: payload };
    case APPROVING:
      return { ...state, approving: payload, approveError: null };
    case APPROVED:
      return { ...state, approving: false, approveError: null, approved: true };
    case APPROVE_ERROR:
      return { ...state, approving: false, approveError: payload };
    case REMOVE_APPROVED:
      return { ...state, ...payload };
    case GET_PENDING_CERTIFICATION_REQUEST:
      return { ...state, pendingCertificationsLoading: true, pendingCertificationsError: null };
    case GET_PENDING_CERTIFICATION_SUCCESS:
      return {
        ...state,
        pendingCertificationsLoading: false,
        pendingCertifications: payload.data,
        pendingCertificationsPagination: payload.metadata,
      };
    case GET_PENDING_CERTIFICATION_ERROR:
      return {
        ...state,
        pendingCertificationsLoading: false,
        pendingCertificationsError: payload,
      };
    case REMOVE_APPROVED_OR_REJECTED_FROM_LIST:
      return {
        ...state,
        pendingCertifications: payload,
      };
    case GET_AFFILIATION_PENDING_REQUEST:
      return {
        ...state,
        affiliationWithListingsRequest: true,
        affiliationWithListingsError: null,
      };
    case GET_AFFILIATION_PENDING_SUCCESS:
      return {
        ...state,
        affiliationWithUsersRefs: payload.data,
        affiliationWithListingsRequest: false,
        affiliationWithListingsError: null,
      };
    case GET_AFFILIATION_PENDING_ERROR:
      return {
        ...state,
        affiliationWithListingsRequest: false,
        affiliationWithListingsError: payload,
      };
    default:
      return state;
  }
};
export const setPagination = response => {
  const payload = response.data.meta;
  // {page, paginationLimit, perPage, totalItems, totalPages
  return {
    type: SET_PAGINATION,
    payload: payload,
  };
};
export const setPageLoading = payload => {
  return {
    type: SET_PAGE_LOADING,
    payload,
  };
};

export const queryListingsRequest = () => ({
  type: GET_LISTINGS_REQUEST,
  payload: {},
});

export const queryListingsSuccess = response => ({
  type: GET_LISTINGS_SUCCESS,
  payload: { data: response.data.data.map(l => l.id) },
});

export const queryListingsError = e => ({
  type: GET_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryProvidersRequest = () => ({
  type: GET_PROVIDER_REQUEST,
  payload: {},
});

export const queryProvidersSuccess = response => ({
  type: GET_PROVIDER_SUCCESS,
  payload: { data: response.data.data.map(l => ({ id: l.id, type: l.type })) },
});

export const queryProvidersError = e => ({
  type: GET_PROVIDER_ERROR,
  error: true,
  payload: e,
});

export const queryPendingApprovalRequest = () => ({
  type: GET_PENDING_APPROVAL_REQUEST,
  payload: {},
});

export const queryPendingApprovalSuccess = response => ({
  type: GET_PENDING_APPROVAL_SUCCESS,
  payload: { data: response.data.data.map(l => l.id) },
});

export const queryPendingApprovalError = e => ({
  type: GET_PENDING_APPROVAL_ERROR,
  error: true,
  payload: e,
});

export const approveRequest = id => ({
  type: APPROVING,
  payload: id,
});
export const approveSuccess = () => ({
  type: APPROVED,
});
export const approveError = id => ({
  type: APPROVE_ERROR,
  payload: id,
});
export const removeApproved = payload => ({
  type: REMOVE_APPROVED,
  payload,
});

export const getPendingCertificationsLoading = () => ({
  type: GET_PENDING_CERTIFICATION_REQUEST,
});

export const getPendingCertificationSuccess = response => ({
  type: GET_PENDING_CERTIFICATION_SUCCESS,
  payload: { data: response.data.data.map(l => l.id) },
});

export const getPendingCertificationsError = payload => ({
  type: GET_PENDING_CERTIFICATION_ERROR,
  payload,
});

export const removeApprovedOrRejectedFromList = payload => ({
  type: REMOVE_APPROVED_OR_REJECTED_FROM_LIST,
  payload,
});

export const getAffiliationWithLoading = () => ({
  type: GET_AFFILIATION_PENDING_REQUEST,
});

export const getAffiliationWithSuccess = response => ({
  type: GET_AFFILIATION_PENDING_SUCCESS,
  payload: { data: response.data.data.map(l => ({ id: l.id, type: l.type })) },
});

export const getAffiliationWithError = payload => ({
  type: GET_AFFILIATION_PENDING_ERROR,
  payload,
});
export default dashboardReducer;

export const approveListing = params => (dispatch, getState, sdk) => {
  const { id, type, rating, state, category, subCategory, authorId } = params;
  dispatch(approveRequest({ id, type }));
  const approveURL = `${apiBaseUrl()}/api/admin/approveListing?id=${id}`;
  const updateURL = `${apiBaseUrl()}/api/admin/updateListingById`;
  const updateUserURL = `${apiBaseUrl()}/api/user/onPublishOrDeleteCertifiedListing`;
  const updateValues = {
    id,
    publicData: {
      isCertificateVerified: type,
      lastCertifiedRating: rating,
    },
  };
  return axios
    .post(updateURL, updateValues)
    .then(res => {
      if (type === 'accepted' && state === 'pendingApproval') {
        const promisesToBeResolved = [axios.get(approveURL)];
        if (category === 'animalProfessional' && subCategory === 'certifiedAnimalWrangler') {
          promisesToBeResolved.push(
            axios.post(
              updateUserURL,
              {
                authorId,
                listingAction: 'published',
              },
              { withCredentials: true }
            )
          );
        }
        return Promise.all([...promisesToBeResolved]);
      } else {
        return res;
      }
    })
    .then(res => {
      const currentIds = getState().Dashboard.pendingApprovalIds;
      const payload = currentIds.filter(lid => lid.uuid !== id);
      dispatch(approveSuccess());
      dispatch(removeApproved({ pendingApprovalIds: payload }));
    })
    .catch(e => {
      console.log(e);
      dispatch(approveError(id));
    });
};

export const queryUserListings = params => (dispatch, getState, sdk) => {
  const { currentUser, page, tab, perPage = 10, states = null, keywords = null } = params;
  const authorId = currentUser.id.uuid;

  const aspectWidth = 1;
  const aspectHeight = 1;
  const variantPrefix = 'listing-card';
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(queryListingsRequest());
  const queryParams = {
    states,
    pub_listingCategory:
      tab === 'castingCall' || tab === 'myCastingCall' ? 'castingCall' : 'listing',
    authorId: currentUser.id.uuid,
    page,
    include: ['images', 'author'],
    authorId,
    perPage,
    keywords,

    'fields.image': [
      'variants.default',
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.square-small`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  };
  return axios
    .post(`${apiBaseUrl()}/api/admin/getListingsByParams`, queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response.data));
      dispatch(queryListingsSuccess(response.data));
      dispatch(setPagination(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};
export const queryAllListings = params => (dispatch, getState, sdk) => {
  const { tab, page, keywords = null } = params;
  const listingCategory =
    tab === 'listings' ? 'listing' : tab === 'castingCall' ? 'castingCall' : null;
  dispatch(queryListingsRequest());
  const queryParams = {
    page,
    perPage: 10,
    include: ['images', 'author'],
    pub_listingCategory: listingCategory,
    keywords,
  };
  return sdk.listings
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(setPagination(response));
      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};
export const getPendingApprovalsListing = ({ page, keywords = null }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(queryPendingApprovalRequest());
  return axios
    .post(`${apiBaseUrl()}/api/admin/getListingsByParams`, {
      states: 'pendingApproval',
      perPage: 10,
      page,
      // pub_ratings: 'level-0',
      include: ['images', 'author'],
      keywords,
    })
    .then(res => {
      dispatch(addMarketplaceEntities(res.data));
      dispatch(queryPendingApprovalSuccess(res.data));

      dispatch(setPagination(res.data));
      return res;
    })
    .catch(e => {
      dispatch(queryPendingApprovalError(storableError(e)));
      throw e;
    });
};
export const getAllProviders = params => (dispatch, getState, sdk) => {
  const { tab, page } = params;
  dispatch(queryProvidersRequest());
  return axios
    .post(`${apiBaseUrl()}/api/admin/getUsersByParams`, {
      pub_userType: tab === 'providers' ? 'provider' : 'customer',
      page,
      include: ['profileImage', 'profile.firstName', 'profile.lastName'],
      'fields.image': [
        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      perPage: 10,
    })
    .then(res => {
      dispatch(addMarketplaceEntities(res.data));
      dispatch(queryProvidersSuccess(res.data));
      dispatch(setPagination(res.data));
      return res;
    })
    .catch(e => {
      dispatch(queryProvidersError(storableError(e)));
      throw e;
    });
};
export const getPendingCertifications = ({ currentUser, page, keywords = null }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(getPendingCertificationsLoading());
  const url = `${apiBaseUrl()}/api/admin/getListingsByParams`;
  const {
    id: { uuid },
    attributes: {
      profile: { publicData },
    },
  } = currentUser;
  const providerCategory = publicData?.providerCategory;
  const idParams =
    providerCategory === 'agency'
      ? { pub_certifiedWranglerAgency: uuid }
      : { pub_certifiedWrangler: uuid };
  const queryParams = {
    states: ['pendingApproval'],
    ...idParams,
    pub_isCertificateVerified: 'pending',
    include: ['images', 'author'],
    page,
    perPage: 10,
    keywords,
  };

  return axios
    .post(url, queryParams, { withCredentials: true })
    .then(res => {
      const data = res.data;
      dispatch(addMarketplaceEntities(data));
      dispatch(setPagination(data));
      dispatch(getPendingCertificationSuccess(data));
    })
    .catch(err => {
      console.log(err);
      dispatch(getPendingCertificationsError(err));
    });
};

//needs to be modified
export const approveOrRejectByCertifiedWrangler = ({
  id,
  type,
  state,
  rating,
  category,
  subCategory,
  authorId,
}) => (dispatch, getState, sdk) => {
  dispatch(approveRequest(type));
  const approveURL = `${apiBaseUrl()}/api/admin/approveListing?id=${id}`;
  const updateURL = `${apiBaseUrl()}/api/admin/updateListingById`;
  const updateUserURL = `${apiBaseUrl()}/api/user/onPublishOrDeleteCertifiedListing`;
  const updateValues = {
    id,
    publicData: {
      isCertificateVerified: type,
      lastCertifiedRating: rating,
    },
  };
  return axios
    .post(updateURL, updateValues)
    .then(res => {
      if (type === 'accepted' && state === 'pendingApproval') {
        const promisesToBeResolved = [axios.get(approveURL)];
        if (category === 'animalProfessional' && subCategory === 'certifiedAnimalWrangler') {
          promisesToBeResolved.push(
            axios.post(
              updateUserURL,
              {
                authorId,
                listingAction: 'published',
              },
              { withCredentials: true }
            )
          );
        }
        return Promise.all([...promisesToBeResolved]);
      } else {
        return res;
      }
    })
    .then(res => {
      const payload = getState().Dashboard.pendingCertifications?.filter(lid => lid.uuid !== id);
      dispatch(approveSuccess());
      dispatch(removeApproved({ pendingCertifications: payload }));
    });
};

export const getAffiliationPendings = ({ page, currentUser, tab }) => (dispatch, getState, sdk) => {
  dispatch(getAffiliationWithLoading());
  const affiliationWithId = currentUser?.id.uuid;
  const providerCategory = currentUser?.attributes?.profile?.publicData?.providerCategory;
  const url = `${apiBaseUrl()}/api/admin/getUsersByParams`;
  if (!affiliationWithId) {
    const err = new Error('User id not found');
    dispatch(getAffiliationWithError(storableError(err)));
    throw err;
  }
  const idParams =
    providerCategory === 'agency'
      ? { pub_affiliationWithAgencyId: affiliationWithId }
      : { pub_affiliationWithId: affiliationWithId };
  return axios
    .post(url, {
      ...idParams,
      pub_providerCategory:
        tab === 'wranglerApproval' ? 'wranglers' : tab === 'affiliationPending' ? 'petOwner' : null,
      pub_affiliationWithApprovalStatus: 'pending',
      include: ['profileImage'],
      'fields.image': [
        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      page,
      perPage: 10,
    })
    .then(res => {
      dispatch(addMarketplaceEntities(res.data));
      dispatch(getAffiliationWithSuccess(res.data));
      dispatch(setPagination(res.data));
      return res;
    })
    .catch(e => {
      dispatch(getAffiliationWithError(storableError(e)));
      throw e;
    });
};

export const acceptOrRejectAffiliationWithUser = ({ id, type, mailParams }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(approveRequest({ id, type }));
  const url = `${apiBaseUrl()}/api/admin/updateUserById`;
  const params = {
    id,
    publicData: {
      affiliationWithApprovalStatus: type === 'accept' ? 'accepted' : 'rejected',
    },
  };
  const initialPromise =
    type === 'accept'
      ? axios.post(`${apiBaseUrl()}/api/user/updateAffiliation`, { id }, { withCredentials: true })
      : Promise.resolve();

  return initialPromise
    .then(res => {
      return axios.post(url, params);
    })
    .then(res => {
      const currentIds = getState().Dashboard.affiliationWithUsersRefs;
      const payload = currentIds.filter(lid => lid.id.uuid !== id);
      dispatch(approveSuccess());
      dispatch(removeApproved({ affiliationWithUsersRefs: payload }));
      if (type === 'accept') {
        const mailBody = {
          ...mailParams,
        };
        axios.post(`${apiBaseUrl()}/api/mail/accountCertified`, mailBody);
      }
    })
    .catch(e => {
      console.log(e);
      dispatch(approveError(id));
    });
};

export const getPendingAffiliationForAdmin = ({ currentUser, tab, page }) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(getAffiliationWithLoading());
  const userType = {
    pub_providerCategory:
      tab === 'wranglerApproval'
        ? 'wranglers'
        : tab === 'agencyApproval'
        ? 'agency'
        : 'has_any:trainer,petOwner',
  };
  const queryParams = {
    pub_affiliationWithApprovalStatus: 'pending',
    ...userType,
    include: ['profileImage'],
    'fields.image': [
      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    page,
    perPage: 10,
  };
  return axios
    .post(`${apiBaseUrl()}/api/admin/getUsersByParams`, queryParams)
    .then(res => {
      dispatch(addMarketplaceEntities(res.data));
      dispatch(getAffiliationWithSuccess(res.data));
      dispatch(setPagination(res.data));
    })
    .catch(e => {
      dispatch(getAffiliationWithError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  dispatch(setPageLoading(true));
  await dispatch(fetchCurrentUser());
  const currentUser = getState().user.currentUser;
  const isProvider = currentUser?.attributes?.profile?.publicData?.userType === 'provider';
  const isSuperAdmin = currentUser?.attributes?.profile?.publicData?.isSuperAdmin;
  const tab = params.tab;
  const { searchKeywords: keywords = '', page = 1, affiliateTab } = parse(search);
  if (isSuperAdmin) {
    if (tab === 'affiliate') {
      return dispatch(getPageUsers({ page, affiliateTab, search })).then(res => {
        return dispatch(setPageLoading(false));
      });
    } else if (tab === 'pendingApproval') {
      return dispatch(getPendingApprovalsListing({ page, keywords }));
    } else if (tab === 'providers' || tab === 'customers') {
      return dispatch(getAllProviders({ tab, page }));
    } else if (tab === 'listings' || tab === 'castingCall') {
      return dispatch(queryAllListings({ tab, page, keywords }));
    } else if (tab === 'wranglerApproval' || tab === 'agencyApproval' || tab === 'userApproval') {
      return dispatch(getPendingAffiliationForAdmin({ tab, page, currentUser }));
    } else if (tab === 'myListings' || tab === 'myCastingCall') {
      return dispatch(queryUserListings({ currentUser, page, tab, keywords }));
    } else {
      return Promise.resolve();
    }
  } else {
    if (tab === 'affiliate') {
      return dispatch(getAffiliationData({ page, affiliateTab, search })).then(res => {
        return dispatch(setPageLoading(false));
      });
    } else if (tab === 'listings' || tab === 'castingCall') {
      return dispatch(queryUserListings({ currentUser, page, tab, keywords }));
    } else if (tab === 'pendingApproval') {
      return dispatch(getPendingCertifications({ currentUser, page, keywords }));
    } else if (tab === 'affiliationPending' || tab === 'wranglerApproval') {
      return dispatch(getAffiliationPendings({ page, currentUser, tab }));
    } else {
      return Promise.resolve();
    }
  }
};
