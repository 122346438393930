import React, { useContext, useEffect, useState } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, Avatar, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

import { ReactComponent as ReviewStartIcon } from '../../assets/icons/reviewStar.svg';
import { ReactComponent as LikeButton } from '../../assets/icons/wishList.svg';
import { ReactComponent as CertifiedBadgeIcon } from '../../assets/icons/certifiedCheck.svg';
import { ToastContext } from '../../containers/Toast/ToastContext';

import { types as sdkTypes } from '../../util/sdkLoader';
import moment from 'moment';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const MAX_FAVOURITES = 20;
const { Money } = sdkTypes;

const priceData = (price, currency, intl) => {
  const priceAsMoney =
    price instanceof Money ? price : new Money(price.amount, price?.currency || currency);
  if (priceAsMoney && priceAsMoney.currency === currency) {
    const formattedPrice = formatMoney(intl, priceAsMoney);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (priceAsMoney) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: priceAsMoney.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: priceAsMoney.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } =
    typeof price?.amount === 'number' ? priceData(price, config.currency, intl) : {};
  return (
    <div className={css.price}>
      {price?.amount ? (
        <div className={css.priceValue} title={priceTitle}>
          {formattedPrice}
        </div>
      ) : null}
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    isFavourite,
    onAddOrRemoveFavourtites,
    adding,
    favourites,
  } = props;
  const { toggleToast } = useContext(ToastContext);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData, metadata, createdAt } = currentListing.attributes;

  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;
  const isCertifiedListing =
    typeof publicData?.ratings !== 'undefined' &&
    publicData?.ratings !== 'level-0' &&
    // publicData?.ratings === publicData?.lastCertifiedRating &&
    publicData?.isCertificateVerified === 'accepted';
  // const createdAtInDays = moment().diff(moment(createdAt), 'days');
  const formattedCreatedAt = moment(createdAt).fromNow();
  const totalReviewRating = metadata?.totalReviewRating || 0;
  const totalReviewContent = metadata?.totalReviewContent || 0;
  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={['default']}
          sizes={renderSizes}
        />
        <div className={css.favContainer}>
          <button
            type="button"
            className={classNames({
              [css.favload]: !adding,
              [css.favBtn]: !isFavourite,
              [css.unfavBtn]: isFavourite,
            })}
            onClick={e => {
              if (adding) return;
              e.stopPropagation();
              e.preventDefault();
              if (favourites?.length === MAX_FAVOURITES && !isFavourite)
                return toggleToast('Favourites limit reached');
              onAddOrRemoveFavourtites(listing.id.uuid, favourites);
            }}
          >
            <LikeButton />
          </button>
        </div>
        {isCertifiedListing && (
          <div className={css.certifiedBadgeContainer}>
            <CertifiedBadgeIcon />
            <span>Certified</span>
          </div>
        )}
        {publicData?.listingCategory === 'castingCall' && (
          <span className={css.castTag}>Casting Call</span>
        )}
      </AspectRatioWrapper>
      {showAuthorInfo ? (
        <div className={css.authorInfo}>
          <div className={css.infoContainer}>
            <Avatar className={css.avatar} user={author} disableProfileLink />

            <FormattedMessage id="ListingCard.author" values={{ authorName }} />
          </div>

          {totalReviewRating > 0 && totalReviewContent > 0 ? (
            <div className={css.reviewSection}>
              <ReviewStartIcon />
              <div className={css.rating}>{totalReviewRating}</div>
              <span>({totalReviewContent})</span>
            </div>
          ) : null}
        </div>
      ) : null}

      <div className={css.mainInfo}>
        <div className={css.title}>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </div>
      </div>
      <div className={css.info}>
        {publicData?.listingCategory === 'castingCall' ? (
          <span className={css.priceValue}>Posted {formattedCreatedAt}</span>
        ) : (
          <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
        )}
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
