import axios from 'axios';
import React, { useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { compose } from 'redux';
import { apiBaseUrl } from '../../util/api';
import { IconSpinner } from '../../components';
export const AFFILIATE_LOCAL_STORAGE_KEY = 'animal-list-affiliate';
export const AFFILIATE_EXPIRY_LOCAL_STORAGE_KEY = 'animal-list-affiliate-expiry';
export const SECRET_KEY = 'the-animal-list';
// Function to encode a string with a secret key
// Function to encrypt a string with a secret key
const encryptWithSecret = (str, key) => {
  return CryptoJS.AES.encrypt(str, key).toString();
};

// Function to decrypt a string with a secret key
const decryptWithSecret = (ciphertext, key) => {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    return null;
  }
};
// Function to check if the stored time has passed and delete the key if so
export const checkAndDeleteKey = () => {
  const encodedTimestamp =
    typeof window !== 'undefined' &&
    window.localStorage.getItem(AFFILIATE_EXPIRY_LOCAL_STORAGE_KEY);
  if (!encodedTimestamp) return;

  const timestamp = decryptWithSecret(encodedTimestamp, SECRET_KEY);
  const currentTime = Date.now();
  if (currentTime > parseInt(timestamp, 10)) {
    typeof window !== 'undefined' &&
      window.localStorage.removeItem(AFFILIATE_EXPIRY_LOCAL_STORAGE_KEY);
  }
};
const AffiliateLander = props => {
  const { params } = props;
  useEffect(() => {
    axios
      .get(`${apiBaseUrl()}/api/affiliate-program/get-affiliate-link-data`, {
        headers: {
          ['affiliate-token']: params.token,
        },
      })
      .then(res => {
        if (
          typeof window !== 'undefined' &&
          typeof window.localStorage !== 'undefined' &&
          res.data?.url &&
          res.data?.key
        ) {
          const currentDate = new Date();
          const futureDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);

          const futureDateTimestamp = futureDate.getTime().toString();
          const encodedTimestamp = encryptWithSecret(futureDateTimestamp, SECRET_KEY);

          // Save the encoded timestamp in local storage
          window.localStorage.setItem(AFFILIATE_EXPIRY_LOCAL_STORAGE_KEY, encodedTimestamp);
          window.localStorage.setItem(AFFILIATE_LOCAL_STORAGE_KEY, res.data?.key);
          window.location.href = res.data.url;
        } else {
          if (typeof window !== 'undefined') window.location.href = '/';
        }
      })
      .catch(err => {});
  }, []);
  return (
    <div>
      <IconSpinner />
    </div>
  );
};

export default compose(withRouter)(AffiliateLander);
