import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import { ReactComponent as InboxIcon } from '../../../assets/icons/Mail.svg';
import { ReactComponent as FavIcon } from '../../../assets/icons/Heart.svg';
import { ReactComponent as ExpandIcon } from '../../../assets/icons/SeeMore.svg';

import css from './TopbarDesktop.module.css';
import { createResourceLocatorString } from '../../../util/routes';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    isCustomTopbar,
    history,
    location,
  } = props;
  const userType = currentUser?.attributes?.profile?.publicData?.userType;
  const routes = useRouteConfiguration();
  const [mounted, setMounted] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [currType, setcurrType] = useState(
    (typeof window !== 'undefined' && window.localStorage.getItem('switchedType')) || userType
  );

  const isProvider = userType === 'provider';
  const isCustomer = userType === 'customer';
  const providerCategory = currentUser?.attributes?.profile?.publicData?.providerCategory;
  useEffect(() => {
    if (clicked) {
      const isDashboard = location?.pathname?.includes('/dashboard/');
      const route = isDashboard
        ? 'Dashboard'
        : currType === 'provider'
        ? 'SellerLandingPage'
        : 'LandingPage';
      history.push(
        createResourceLocatorString(route, routes, isDashboard ? { tab: 'listings' } : {})
      );
    }
  }, [currType]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        {/* <FormattedMessage id="TopbarDesktop.inbox" /> */}
        <div className={css.iconContainer}>
          <InboxIcon />
        </div>
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };
  const switchButtonMaybe = (
    <button
      className={css.switchBtn}
      type="button"
      onClick={e => {
        typeof window !== 'undefined' &&
          window.localStorage.setItem(
            'switchedType',
            currType === 'provider' ? 'customer' : 'provider'
          );
        setClicked(true);
        setcurrType(currType === 'provider' ? 'customer' : 'provider');
      }}
    >
      Switch to {currType === 'provider' ? 'Buyer' : 'Seller'}
    </button>
  );
  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="switch button">{switchButtonMaybe}</MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
            to={
              isProvider
                ? { search: `type=provider&category=${providerCategory}` }
                : { search: `type=customer` }
            }
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="Dashboard">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('Dashboard'))}
            name="Dashboard"
            params={{ tab: 'listings' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.dashboardlink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;
  const moreMenu = (
    <Menu>
      <MenuLabel className={css.moreMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <FormattedMessage id="TopbarDesktop.more" />
      </MenuLabel>
      <MenuContent className={classNames(css.profileMenuContent, css.moreMenuContent)}>
        <MenuItem key="FaqPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('FaqPage'))}
            name="FAQPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.faq" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="About">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('About'))}
            name="AboutUs"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.about" />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        format="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />
      {!isCustomTopbar && search}

      {!isCustomTopbar && isAuthenticated && (
        <div className={css.rightMenu}>
          <div className={css.menuSection}>
            {inboxLink}

            <NamedLink className={css.createListingLink} name="FavouritesPage">
              <span className={classNames(css.createListingLink, css.iconContainer)}>
                <FavIcon />
              </span>
            </NamedLink>

            {/* <NamedLink className={css.createListingLink} name="LandingPage">
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.openCastingCall" />
              </span>
            </NamedLink> */}
            {(isProvider && currType === 'customer') || isCustomer ? (
              // <NamedLink className={css.createListingLink} name="PostCastingCall">
              <NamedLink className={css.createListingLink} name="NewCastingCallPage">
                <span className={css.createListing}>
                  <FormattedMessage id="TopbarDesktop.postCastingCall" />
                </span>
              </NamedLink>
            ) : null}

            {(isProvider && currType !== 'customer') || currType === 'provider' ? (
              // <NamedLink
              //   className={classNames(css.createListingLink, css.animllstbtn)}
              //   name="PostListing"
              // >
              <NamedLink
                className={classNames(css.createListingLink, css.animllstbtn)}
                name="PostListingGuide"
              >
                <span className={css.createListing}>
                  <FormattedMessage id="TopbarDesktop.createListing" />
                </span>
              </NamedLink>
            ) : null}

            {loginLink}
            {signupLink}
          </div>
          {profileMenu}
        </div>
      )}
      {!isCustomTopbar && !isAuthenticated && (
        <div className={css.rightMenu}>
          <div className={css.menuSection}>
            <NamedLink className={css.createListingLink} name="NewCastingCallPage">
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.postCastingCall" />
              </span>
            </NamedLink>
            <NamedLink
              className={classNames(css.createListingLink, css.animllstbtn)}
              name="PostListingGuide"
            >
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.createListing" />
              </span>
            </NamedLink>
            {/* <NamedLink className={css.createListingLink} name="LandingPage">
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.openCastingCall" />
              </span>
            </NamedLink>
            <NamedLink className={css.createListingLink} name="NewListingPage">
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.postCastingCall" />
              </span>
            </NamedLink> */}
            {/* <NamedLink className={css.createListingLink} name="LandingPage">
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.more" />
              </span>
            </NamedLink> */}
            {moreMenu}
            {inboxLink}
            {loginLink}
            {signupLink}
          </div>
          {profileMenu}
        </div>
      )}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
