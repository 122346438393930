import React, { createContext, useState } from 'react';
import css from './Toast.module.css';
// Create Context
export const ToastContext = createContext({ toggleToast: () => {} });

// Provider Component
export const ToastProvider = ({ children }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastContent, setToastContent] = useState(false);
  const toggleToast = message => {
    setToastContent(message);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 1000);
  };
  const toast = showToast ? <div className={css.toast}>{toastContent}</div> : null;
  return (
    <ToastContext.Provider value={{ toggleToast }}>
      {toast}
      {children}
    </ToastContext.Provider>
  );
};
